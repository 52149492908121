import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import ScrolledIntoView from '../../ScrolledIntoView'
import Column from '../ui/Sections/Column'
import Heading from '../ui/Typography/Heading'
import MediumHeading from '../ui/Typography/MediumHeading'
import SmallHeading from '../ui/Typography/SmallHeading'
import {connect} from 'react-redux'
import {
  fetchProjects
} from '../../actions'
import {BASE_PATH} from '../../paths'
import Button from '../ui/Styles/SlickButton'
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet"

class Projects extends React.Component {

  componentDidMount(){
    this.props.fetchProjects()
  }

  renderColumns = () => {
    return this.props.projects.map((item, index) => {
      return (
        <Column className="w_item" key={index}>
          <Link to={`/projects/${item.slug}`}>
            <img src={BASE_PATH + item.image} alt={item.title} />
            <MediumHeading>{item.title}</MediumHeading>
            <SmallHeading color="#D1D3D5">{item.description}</SmallHeading>
            <Button link={`/projects/${item.slug}`}>View project</Button>
          </Link>
        </Column>
      )
    })
  }

  render(){
    return(
      <React.Fragment>
        <Helmet>                      
            <title>Projects | Westgate & Keële</title>
            <link rel="canonical" href={window.location} />
            <meta name="robots" content="index, follow" />
            <meta
                name="description"
                content="With over 20 years’ experience in building and project management, we offer residential and commercial conversion expertise with a personal touch."
            />
        </Helmet>
        <Layout>
          <ScrolledIntoView>
              <Section className="w_section-projects">
                  <Column className="w_heading-column">
                      <Heading>Projects</Heading>
                  </Column>
                  <div className="w_items-contailer">
                    {this.props.projects && this.renderColumns()}
                  </div>
              </Section>
          </ScrolledIntoView>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    projects: Object.values(state.projectsState)
  }
}

export default connect(mapStateToProps, {
  fetchProjects
})(Projects)
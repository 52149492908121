import React from 'react'
import FooterBottom from './FooterBottom'

class Footer extends React.Component {
  render(){
    return(
      <footer className="w_footer">
        <FooterBottom />
      </footer>
    )
  }
}

export default Footer
import React from 'react'

class SlickMenuIcon extends React.Component {
  render(){
    return(
      <button className={`w_slick-menu-icon ${this.props.className}`} onClick={this.props.onClick}>
        <div className="w_inner">
            <span /><span /><span /><span />
        </div>
      </button>
    )
  }
}

export default SlickMenuIcon
import React from 'react'
import NavLinks from './NavLinks'

class MobileNav extends React.Component {
  render(){
    return(
      <nav className={`w_mobile-nav ${this.props.className}`}>
        <NavLinks />
      </nav>
    )
  }
}

export default MobileNav
import React from 'react'
import Column from './Column'
import AboveSectionHeading from '../Styles/AboveSectionHeading'
import SectionHeading from '../Typography/SectionHeading'
import Copy from '../Typography/Copy'
import SlickButton from '../Styles/SlickButton'
import ScrolledIntoView from '../../../ScrolledIntoView'

class SplitSection2 extends React.Component {
    
  render(){
    return(
        <ScrolledIntoView fade>
            <section 
                className={`w_spit-section-2 ${this.props.className ? this.props.className : ''} ${this.props.reverse ? ' w_column-right' : ''}`}
            >
                <img src={this.props.img} alt={this.props.sectionHeading} />
                <div className="w_inner">
                    <Column id={this.props.id ? this.props.id : ''}>
                        <ScrolledIntoView 
                            rtl={this.props.rtl ? true : false}
                            ltr={this.props.ltr ? true : false}
                        >
                            {
                                this.props.aboveSectionHeading && (
                                    <AboveSectionHeading>
                                        {this.props.aboveSectionHeading}
                                    </AboveSectionHeading>
                                )
                            }
                            <div className="w_column-inner">                
                                {
                                    this.props.sectionHeading && (
                                        <SectionHeading>
                                            {this.props.sectionHeading}
                                        </SectionHeading>
                                    )
                                }
                                <Copy color={this.props.color ? this.props.color : "#8E8E8E"}>
                                    {this.props.children}
                                </Copy>
                            </div>
                            {
                                this.props.link && (                                    
                                    <SlickButton link={this.props.link}>
                                        {this.props.linkText ? this.props.linkText : "Some Text"}
                                    </SlickButton>
                                )
                            }  
                        </ScrolledIntoView>          
                    </Column>
                </div>
                <div className="clearfix" />
            </section>
        </ScrolledIntoView>
    )
  }
}

export default SplitSection2
import React from 'react'
import {Link} from 'react-router-dom'
import logoWds from '../../../assets/img/wandk-logo.svg'
import logoMb from '../../../assets/img/wandk-logo-mobile.svg'

class Logo extends React.Component {
  render(){
    return(
      <Link className="w_logo" to="/">
        <img src={logoWds} alt="Westgate And Keele" className="w_widescreen" />
        <img src={logoMb} alt="Westgate And Keele" className="w_mobile" />
      </Link>
    )
  }
}

export default Logo
import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import Column from '../ui/Sections/Column'
import Heading from '../ui/Typography/Heading'
import Copy from '../ui/Typography/Copy'
import SlickButtonFill from '../ui/Styles/SlickButtonFill'
import ScrolledIntoView from '../../ScrolledIntoView'

class Thankyou extends React.Component {
  render(){
    return(
      <Layout>
        <ScrolledIntoView>
            <Section className="w_section-thankyou-page">
                <Column className="w_heading-column">
                    <Heading>Thank You</Heading>
                    <Copy>
                        <p>Thank you for contacting us. We will get back to you shortly</p>
                    </Copy>
                    <SlickButtonFill link="/">Home Page</SlickButtonFill>
                </Column>
            </Section>
        </ScrolledIntoView>
      </Layout>
    )
  }
}

export default Thankyou
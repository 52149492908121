import React from 'react'
import {Link} from 'react-router-dom'

class FooterBottom extends React.Component {
  render(){
    const year = new Date()
    return(
      <div className="w_footer-bottom">
          <p>© {year.getFullYear()} Westgate & Keële | <Link to="accreditations">Accreditations</Link> | <Link to="/terms-of-use">Terms Of Use</Link> | <a href="https://optimalonline.co.za" target="_blank" rel="noreferrer">Optimal Online</a></p>
      </div>
    )
  }
}

export default FooterBottom
import {
    ADMIN_CREATE_PROJECT_SUBMIT,
    ADMIN_FETCH_PROJECTS,
    ADMIN_FETCH_PROJECT,
    ADMIN_UPDATE_PROJECT,
    ADMIN_DELETE_PROJECT
} from '../actions/types'
import _ from 'lodash'

const initialState = {}

const adminProjectsReducer = (state = initialState, action) => {    
    switch (action.type){
        case ADMIN_FETCH_PROJECTS:
            return {
                ...state,
                ..._.mapKeys(action.payload, 'id')
            }
        case ADMIN_FETCH_PROJECT:
            return {
                ...state,
                [action.payload.id]: action.payload
            }
        case ADMIN_CREATE_PROJECT_SUBMIT:
            return {
                ...state,
                [action.payload.id ? action.payload.id : 'new_project']: action.payload
            }
        case ADMIN_UPDATE_PROJECT:
            if(action.payload.id){
                return {
                    ...state,
                    [action.payload.id]: action.payload
                }
            }
            return state
            
        case ADMIN_DELETE_PROJECT:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default adminProjectsReducer
import React from 'react'
import NavLinks from './NavLinks'

class WidescreenNav extends React.Component {
  render(){
    return(
      <nav className="w_widescreen-nav">
        <NavLinks />
      </nav>
    )
  }
}

export default WidescreenNav
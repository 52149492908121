import {
    ADMIN_PROJECT_GALLERY_UPLOAD
} from '../actions/types'

const initialState = {}

const adminGalleryReducer = (state = initialState, action) => {    
    switch (action.type){
        case ADMIN_PROJECT_GALLERY_UPLOAD:
            return { ...state, [action.payload.projectId]: action.payload.data }
        default:
            return state
    }
}

export default adminGalleryReducer
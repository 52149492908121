import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import Heading from '../ui/Typography/Heading'
import Copy from '../ui/Typography/Copy'
import ScrolledIntoView from '../../ScrolledIntoView'
import trustmarkLogo from '../../assets/img/trustmark@2x.png'
import mbaLogo from '../../assets/img/fma-logo@2x.jpg'
import houzzLogo from '../../assets/img/ps_houzz_02.webp'

class Accreditations extends React.Component {
  render(){
    return(
      <Layout>
        <ScrolledIntoView>
            <Section className="w_section-information">
              <Heading>
              Accreditations
              </Heading>
              <Copy>
                <p><strong><img src={trustmarkLogo} alt="TRUST MARK LOGO" className="w_logo" /></strong></p>
                <p>The homeowner section houses everything you would want or need to know before using a TrustMark Registered Business.</p>
 
                <p>We provide a high level of assurance, certainty and protection to homeowners looking to have work done in and around their homes. TrustMark Registered Businesses are thoroughly vetted and continually monitored to ensure they meet required standards in technical competence, customer service and trading practices, so homeowners can be sure they’re making the right choice.</p>

                <p>And, if anything should go wrong, TrustMark has that covered too as Registered Businesses must provide guarantees on their work and have a clear and simple disputes resolution process in place.</p>

                {/*<p><strong><img src={mbaLogo} alt="MASTER BUILDER LOGO" className="w_logo" /></strong></p>
                <p><strong>WHY I WILL BUILD BETTER FOR YOU AS A MASTER BUILDER</strong></p>
                <p>The Master Builder badge is recognised as the sign of building quality. To gain Master Builder status my company has been professionally vetted. I had to demonstrate a minimum of 12 months trading, have public and employers' liability insurance, and pass credit and director checks.</p>

                <p><strong>As a Master Builder:</strong></p>

                <ul>
                    <li>I have agreed to abide by FMB's Code of Practice.</li>
                    <li>I can offer you a contract on the building project so we both know where we stand.</li>
                    <li>I can offer you a warranty on any work I carry out through FMB Insurance Services – their policies are underwritten by Lloyds of London so you can be assured you're getting the cover you need.</li>
                    <li>I have agreed to cooperate with the FMB Dispute Service, so should anything go wrong they can provide Alternative Dispute Resolution.</li>
                    <li>I have Public and Employers liability insurance and passed credit and director checks on joining.</li>
                </ul>*/}

                <p><strong><img className="w_logo" src={houzzLogo} alt="" /></strong></p>
                <p>houzz provides the best experience for home renovation and design, connecting homeowners and home professionals with the best tools, resources and vendors.</p>


              </Copy>
              
            </Section>
          </ScrolledIntoView>
      </Layout>
    )
  }
}

export default Accreditations
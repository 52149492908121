//public
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM'
export const SUBMIT_CONTACT_FORM_FAILED = 'SUBMIT_CONTACT_FORM_FAILED'
export const RESET_SUBMIT_CONTACT_FORM_STATE = 'RESET_SUBMIT_CONTACT_FORM_STATE'
export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECT = 'FETCH_PROJECT'

//auth
export const ADMIN_SIGNIN = 'ADMIN_SIGNIN'
export const ADMIN_SIGNIN_FAILED = 'ADMIN_SIGNIN_FAILED'

//admin
export const ADMIN_SIGNOUT = 'ADMIN_SIGNOUT'
export const ADMIN_CREATE_PROJECT_SUBMIT = 'ADMIN_CREATE_PROJECT_SUBMIT'
export const ADMIN_CREATE_PROJECT_FAILED = 'ADMIN_CREATE_PROJECT_FAILED'
export const ADMIN_CREATE_PROJECT_RESET = 'ADMIN_CREATE_PROJECT_RESET'
export const ADMIN_FETCH_PROJECTS = 'ADMIN_FETCH_PROJECTS'
export const ADMIN_FETCH_PROJECTS_FAILED = 'ADMIN_FETCH_PROJECTS_FAILED'
export const ADMIN_FETCH_PROJECT = 'ADMIN_FETCH_PROJECT'
export const ADMIN_FETCH_PROJECT_FAILED = 'ADMIN_FETCH_PROJECT_FAILED'
export const ADMIN_UPDATE_PROJECT = 'ADMIN_UPDATE_PROJECT'
export const ADMIN_UPDATE_PROJECT_FAILED = 'ADMIN_UPDATE_PROJECT_FAILED'
export const ADMIN_UPDATE_PROJECT_RESET = 'ADMIN_UPDATE_PROJECT_RESET'
export const ADMIN_DELETE_PROJECT = 'ADMIN_DELETE_PROJECT'
export const ADMIN_DELETE_PROJECT_FAILED = 'ADMIN_DELETE_PROJECT_FAILED'

export const ADMIN_PROJECT_GALLERY_UPLOAD = 'ADMIN_PROJECT_GALLERY_UPLOAD'
export const ADMIN_PROJECT_GALLERY_UPLOAD_FAILED = 'ADMIN_PROJECT_GALLERY_UPLOAD_FAILED'

export const ADMIN_USERS = 'ADMIN_USERS'
export const ADMIN_USERS_FAILED = 'ADMIN_USERS_FAILED'
export const ADMIN_USER_DETAILS = 'ADMIN_USER_DETAILS'
export const ADMIN_USER_DETAILS_FAILED = 'ADMIN_USER_DETAILS_FAILED'
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER'
export const ADMIN_CREATE_USER_FAILED = 'ADMIN_CREATE_USER_FAILED'
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER'
export const ADMIN_UPDATE_USER_FAILED = 'ADMIN_UPDATE_USER_FAILED'
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER'
export const ADMIN_DELETE_USER_FAILED = 'ADMIN_DELETE_USER_FAILED'
export const TEST = 'TEST'
import React from 'react'
import WidescreenNav from './WidescreenNav'
import MobileNav from './MobileNav'
import Logo from './Logo'
import SlickMenuIcon from './SlickMenuIcon'
import Footer from './Footer'
import ScrollToTop from '../../../ScrollToTop'
import '../../../assets/scss/app.scss';

class Layout extends React.Component {

  state = {
    mobileNav: false,
    headerTransform: false
  }

  componentDidMount(){
    window.addEventListener('scroll', () => {
      this.handleTransform()
    })
  }

  toggleMobileNav = () => {
    setTimeout(() => {
      this.setState({
        mobileNav: !this.state.mobileNav
      })
    }, 100)    
  }

  handleTransform = () => {

    let y = window.scrollY
    
    if(y > 100){
      if(!this.state.headerTransform){
        this.setState({
          headerTransform: !this.state.headerTransform
        })
      }
    } else {
      if(this.state.headerTransform){
        this.setState({
          headerTransform: !this.state.headerTransform
        })
      }
    }
  }

  render(){
    return(
      <>
        <ScrollToTop />
        <header className={`w_header ${this.state.headerTransform ? 'w_transformed' : ''}`}>
          <div className="w_inner">
            <Logo />
            <WidescreenNav />
            <MobileNav 
              className={this.state.mobileNav ? 'w_open' : ''} 
            />
            <SlickMenuIcon 
              onClick={this.toggleMobileNav} 
              className={this.state.mobileNav ? 'w_close' : ''} 
            />
          </div>
        </header>
        <div className="w_main">
          {this.props.children}
          <Footer />
        </div>
      </>
    )
  }
}

export default Layout
import React from 'react'

class Copy extends React.Component {
  render(){
    return(
      <div className={`w_copy ${this.props.className ? this.props.className : ''}`} style={{
          color: this.props.color ? this.props.color : '#8E8E8E'
      }}>
        {this.props.children}
      </div>
    )
  }
}

export default Copy
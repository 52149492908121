import React from 'react'
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import history from './history'
import { CSSTransition } from 'react-transition-group'

//page components
import Home from './components/pages/Home'
import About from './components/pages/About'
import Services from './components/pages/Services'
import Projects from './components/pages/Projects'
import Project from './components/pages/Project'
import Thankyou from './components/pages/Thankyou'
import SignIn from './components/pages/SignIn'
import PageNotFound from './components/pages/PageNotFound'
import TermsOfUse from './components/pages/TermsOfUse'
import Accreditations from './components/pages/Accreditations'
const Contact = React.lazy(() => import('./components/pages/Contact'))
const SocialFeed = React.lazy(() => import('./components/pages/SocialFeed'))

//dashboard page components
const Dashboard = React.lazy(() => import('./components/pages/Dashboard')) 
const DashboardProjects = React.lazy(() => import('./components/pages/DashboardProjects')) 
const DashboardProjectsCreate = React.lazy(() => import('./components/pages/DashboardProjectsCreate')) 
const DashboardUsers = React.lazy(() => import('./components/pages/DashboardUsers')) 
const DashboardProjectsEdit = React.lazy(() => import('./components/pages/DashboardProjectsEdit')) 
const DashboardProjectsGallery = React.lazy(() => import('./components/pages/DashboardProjectsGallery'))
const DashboardUsersCreate = React.lazy(() => import('./components/pages/DashboardUsersCreate')) 
const DashboardUsersEdit = React.lazy(() => import('./components/pages/DashboardUsersEdit')) 

class App extends React.Component {
  
  render(){

    const routes = [
      { path: '/', name: 'Home', Component: Home },
      { path: '/about', name: 'About', Component: About },
      { path: '/services', name: 'Services', Component: Services },
      { path: '/projects', name: 'Projects', Component: Projects },
      { path: '/projects/:slug', name: 'Project', Component: Project },
      { path: '/social-feed', name: 'Social Feed', Component: SocialFeed },
      { path: '/contact', name: 'Contact', Component: Contact },
      { path: '/thankyou', name: 'Thankyou', Component: Thankyou },
      { path: '/terms-of-use', name: 'Terms Of Use', Component: TermsOfUse },
      { path: '/accreditations', name: 'Accreditations', Component: Accreditations },
      { path: '/login', name: 'SignIn', Component: SignIn },
      { path: '/dashboard', name: 'Dashboard', Component: Dashboard },
      { path: '/dashboard/projects', name: 'Dashboard Projects', Component: DashboardProjects },
      { path: '/dashboard/projects/create', name: 'Dashboard Projects Create', Component: DashboardProjectsCreate },
      { path: '/dashboard/projects/:id/edit', name: 'Dashboard Projects Edit', Component: DashboardProjectsEdit },
      { path: '/dashboard/projects/:id/gallery', name: 'Dashboard Projects Gallery', Component: DashboardProjectsGallery },
      { path: '/dashboard/users', name: 'Dashboard Users', Component: DashboardUsers },
      { path: '/dashboard/users', name: 'Dashboard Users', Component: DashboardUsers },
      { path: '/dashboard/users/create', name: 'Dashboard Users Create', Component: DashboardUsersCreate },
      { path: '/dashboard/users/:id/edit', name: 'Dashboard Users Edit', Component: DashboardUsersEdit },
      { path: '/page-not-found', name: 'Page Not Found', Component: PageNotFound }
    ]

    return(
      <Router history={history}>
        <Switch>
          <div className="w_wrapper">
            <React.Suspense fallback="...loading">
              {routes.map(({ path, Component }) => (
                <Route 
                  exact 
                  key={path} 
                  path={path}
                  >
                  {(props) => (
                    <CSSTransition
                      in={props.match != null}
                      timeout={400}
                      classNames="w_page-transition"
                      unmountOnExit
                    >
                      <div className="w_page">
                        <Component {...props} />
                      </div>
                    </CSSTransition>
                  )}
                </Route>
              ))}
            </React.Suspense>
          </div>
        </Switch>
      </Router>
    )
  }
}

export default App
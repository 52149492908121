import React from 'react'
import {NavLink} from 'react-router-dom'
import SlickButtonFill from '../Styles/SlickButtonFill'

class NavLinks extends React.Component {
  render(){
    return(
      <ul className="w_nav-links">
          <li className="w_nav-link">
            <NavLink exact to="/">Home</NavLink>
          </li>
          <li className="w_nav-link">
            <NavLink exact to="/about">About</NavLink>
          </li>
          <li className="w_nav-link">
            <NavLink exact to="/services">Services</NavLink>
          </li>
          <li className="w_nav-link">
            <NavLink exact to="/projects">Projects</NavLink>
          </li>
          <li className="w_nav-link">
            <NavLink exact to="/social-feed">Social Feed</NavLink>
          </li>
          <li>
            <SlickButtonFill link="/contact">Contact</SlickButtonFill>
          </li>
        </ul>
    )
  }
}

export default NavLinks
import React from 'react'

class Section extends React.Component {
  render(){
    return(
        <section 
          id={this.props.id ? this.props.id : ''}
          className={`
            w_section 
            ${this.props.className ? this.props.className : ''}
          `}
        >
          <div className="w_inner">
            {this.props.children}
          </div>
        </section>
    )
  }
}

export default Section
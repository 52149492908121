import {
    SUBMIT_CONTACT_FORM,
    SUBMIT_CONTACT_FORM_FAILED,
    RESET_SUBMIT_CONTACT_FORM_STATE
} from '../actions/types'

const initialState = {
    success: false,
    response: {},
    error: false
}

const contactFormReducer = (state = initialState, action) => {    
    switch (action.type){
        case SUBMIT_CONTACT_FORM:
            return {
                ...state,
                success: true,
                error: false,
                response: action.payload
            }
        case SUBMIT_CONTACT_FORM_FAILED:
            return {
                ...state,
                success: false,
                error: true,
                response: action.payload.response.data
            }
        case RESET_SUBMIT_CONTACT_FORM_STATE:
            return initialState
        default:
            return state
    }
}

export default contactFormReducer
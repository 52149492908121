import React from 'react'

class SectionHeading extends React.Component {
  render(){
    return(
      <h2 style={{
          color: this.props.color ? this.props.color : '#212529'
      }} className="w_section-heading">{this.props.children}</h2>
    )
  }
}

export default SectionHeading
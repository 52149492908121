import React from 'react'

class Column extends React.Component {
  render(){
    return(
        <div className={`w_column ${this.props.className ? this.props.className : ''}`} id={this.props.id ? this.props.id : ''}>
          {this.props.children}
        </div>
    )
  }
}

export default Column
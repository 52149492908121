import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import Heading from '../ui/Typography/Heading'
import Copy from '../ui/Typography/Copy'
import ScrolledIntoView from '../../ScrolledIntoView'
import Button from '../ui/Styles/SlickButton'

class PageNotFound extends React.Component {
  render(){
    return(
      <Layout>
        <ScrolledIntoView>
            <Section className="w_section-pagenotfound">
              <Heading>
                Page Not Found
              </Heading>
              <Copy>
                <p>Whoops! The page you were looking for does not exist.</p>
                <Button link="/">Go to Home</Button>
              </Copy>
              
            </Section>
          </ScrolledIntoView>
      </Layout>
    )
  }
}

export default PageNotFound
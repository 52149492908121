import React from 'react'
import Layout from '../ui/Layout/Layout'
import AboutTopSection from '../ui/Sections/AboutTopSection'
import {Helmet} from "react-helmet";

class About extends React.Component {
  render(){
    return(
      <React.Fragment>
        <Helmet>                      
            <title>About | Westgate & Keële</title>
            <link rel="canonical" href={window.location} />
            <meta name="robots" content="index, follow" />
            <meta
                name="description"
                content="Westgate & Keële are specialists in full refurbishments, loft conversions and extensions. Over 20 years in the industry we have built up a network of trusted subcontractors and our managing director, Michael, personally oversees all projects from start to finish to ensure we maintain quality workmanship and finish each and every project we take on to a high standard."
            />
        </Helmet>
        <Layout>
          <AboutTopSection />
        </Layout>
      </React.Fragment>
    )
  }
}

export default About
import React from 'react'
import Layout from '../ui/Layout/Layout'
import LinkColumnSection from '../ui/Sections/LinkColumnSection'
import design from '../../assets/img/design-20211109.jpg'
import build from '../../assets/img/build@2x-2.jpg'
import manage from '../../assets/img/manage-20211109.jpg'
import {Helmet} from "react-helmet"

class Home extends React.Component {
  render(){

    const links = [
      {
        img: design,
        link: '#design',
        heading: 'Design',
      },
      {
        img: build,
        link: '#build',
        heading: 'Build',
      },
      {
        img: manage,
        link: '#manage',
        heading: 'Manage',
      },
    ]

    return(
      <React.Fragment>
        <Helmet>                      
              <title>Home | Westgate & Keële</title>
              <link rel="canonical" href={window.location} />
              <meta name="robots" content="index, follow" />
              <meta
                  name="description"
                  content="Westgate & Keële specialise in loft conversions and extensions in South West London."
              />
          </Helmet>
        <Layout>

          <LinkColumnSection links={links} />

        </Layout>
      </React.Fragment>
    )
  }
}

export default Home
import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import Column from '../ui/Sections/Column'
import Heading from '../ui/Typography/Heading'
import AdminAuthForm from '../forms/AdminAuthForm'
import {Helmet} from "react-helmet"

class SignIn extends React.Component {
  render(){
    return(
      <React.Fragment>
        <Helmet>                      
            <title>Admin Sign In | Westgate & Keële</title>
            <link rel="canonical" href={window.location} />
            <meta name="robots" content="noindex, nofollow" />
            <meta
                name="description"
                content="Sign in to dashboard"
            />
        </Helmet>
        <Layout>
          <Section className="w_section-signin-page">
              <Column className="w_heading-column">
                  <Heading>Admin Sign In</Heading>
              </Column>
              <Column>                
                  <AdminAuthForm />
              </Column>
          </Section>
        </Layout>
      </React.Fragment>
    )
  }
}

export default SignIn
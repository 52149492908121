import api from '../apis/admin'

import {
    SUBMIT_CONTACT_FORM,
    SUBMIT_CONTACT_FORM_FAILED,
    RESET_SUBMIT_CONTACT_FORM_STATE,
    ADMIN_SIGNIN,
    ADMIN_SIGNIN_FAILED,
    ADMIN_SIGNOUT,
    ADMIN_CREATE_PROJECT_SUBMIT,
    ADMIN_CREATE_PROJECT_FAILED,
    ADMIN_CREATE_PROJECT_RESET,
    ADMIN_FETCH_PROJECTS,
    ADMIN_FETCH_PROJECTS_FAILED,
    ADMIN_FETCH_PROJECT,
    ADMIN_FETCH_PROJECT_FAILED,
    ADMIN_UPDATE_PROJECT,
    ADMIN_UPDATE_PROJECT_FAILED,
    ADMIN_UPDATE_PROJECT_RESET,
    FETCH_PROJECTS,
    FETCH_PROJECT,
    ADMIN_DELETE_PROJECT,
    ADMIN_DELETE_PROJECT_FAILED,
    ADMIN_USERS,
    ADMIN_USERS_FAILED,
    ADMIN_USER_DETAILS,
    ADMIN_USER_DETAILS_FAILED,
    ADMIN_CREATE_USER,
    ADMIN_CREATE_USER_FAILED,
    ADMIN_UPDATE_USER,
    ADMIN_UPDATE_USER_FAILED,
    ADMIN_DELETE_USER,
    ADMIN_DELETE_USER_FAILED,
    ADMIN_PROJECT_GALLERY_UPLOAD
} from './types'

import history from '../history'

export const submitContactForm = formValues => async dispatch => {
    
    try{

        const response = await api.post('send-contact-email', formValues)

        dispatch({
            type: SUBMIT_CONTACT_FORM,
            payload: response.data
        })

    } catch(error){

        dispatch({
            type: SUBMIT_CONTACT_FORM_FAILED,
            payload: error
        })

    }
}

export const resetSubmitContactForm = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_SUBMIT_CONTACT_FORM_STATE,
        })
    }
}

export const adminSignIn = formValues => async dispatch => {

    try{
        
        const response = await api.post('login', formValues)

        dispatch({
            type: ADMIN_SIGNIN,
            payload: response.data
        })

    } catch(error){
        
        dispatch({
            type: ADMIN_SIGNIN_FAILED,
            payload: error
        })
    }
}

export const adminSignOut = token => async dispatch => {

    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const formValues = {}
        
        await api.post('admin/logout', formValues, config)

        dispatch({
            type: ADMIN_SIGNOUT
        }) 
       
   } catch (error) {
       
        dispatch({
            type: ADMIN_SIGNOUT
        }) 
   }
}


export const adminCreateProjectSubmit = (formValues, token) => async dispatch => {

    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await api.post('admin/projects/create', formValues, config)

        dispatch({
            type: ADMIN_CREATE_PROJECT_SUBMIT,
            payload: response.data
        })

        history.push('/dashboard/projects')
        
    } catch (error) {
        dispatch({
            type: ADMIN_CREATE_PROJECT_FAILED
        })
    }
}

export const resetAdminCreateProject = () => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_CREATE_PROJECT_RESET,
        })
    }
}

export const adminFetchProjects = token => async dispatch => {

    try {
        
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    
        const response = await api.get('admin/projects', config)
    
        dispatch({
            type: ADMIN_FETCH_PROJECTS,
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: ADMIN_FETCH_PROJECTS_FAILED
        })
    }
}

export const adminFetchProject = (id, token) => async dispatch => {

    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    
        const response = await api.get(`admin/projects/${id}/details`, config)
    
        dispatch({
            type: ADMIN_FETCH_PROJECT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: ADMIN_FETCH_PROJECT_FAILED
        })
    }
}

export const adminUpdateProject = (id, formValues, token) => async dispatch => {

    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    
        const response = await api.post(`admin/projects/${id}/update`, formValues, config)
    
        dispatch({
            type: ADMIN_UPDATE_PROJECT,
            payload: response.data
        })

        history.push('/dashboard/projects')
        
    } catch (error) {
        dispatch({
            type: ADMIN_UPDATE_PROJECT_FAILED
        })
    }
}

export const resetAdminUpdateProject = () => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_UPDATE_PROJECT_RESET,
        })
    }
}

export const adminDeleteProject = (id, token) => async dispatch => {
    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        await api.post(`admin/projects/${id}/trash`, {}, config)

        dispatch({
            type: ADMIN_DELETE_PROJECT,
            payload: id
        })
        
    } catch (error) {
        dispatch({
            type: ADMIN_DELETE_PROJECT_FAILED
        })
    }
} 

export const fetchProjects = () => async dispatch => {

    try{

        const response = await api.get('projects')

        dispatch({
            type: FETCH_PROJECTS,
            payload: response.data
        })

    } catch (error){
        console.log(error)
    }
}

export const fetchProject = slug => async dispatch => {

    const response = await api.get('projects/' + slug)

    dispatch({
        type: FETCH_PROJECT,
        payload: response.data
    })
}


export const adminFetchUsers = token => async dispatch => {
    try{

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await api.get('admin/users', config)

        dispatch({
            type: ADMIN_USERS,
            payload: response.data
        })

    } catch(error){
        dispatch({
            type: ADMIN_USERS_FAILED
        })
    }
}

export const adminCreateUser = (formValues, token) => async dispatch => {
    try{

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await api.post('admin/users/create', formValues, config)

        dispatch({
            type: ADMIN_CREATE_USER,
            payload: response.data
        })

        history.push('/dashboard/users')

    } catch(error){
        dispatch({
            type: ADMIN_CREATE_USER_FAILED
        })
    }
}

export const adminUserDetails = (id, token) => async dispatch => {
    try{

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await api.get(`admin/users/${id}/details`, config)

        dispatch({
            type: ADMIN_USER_DETAILS,
            payload: response.data
        })

    } catch(error){
        dispatch({
            type: ADMIN_USER_DETAILS_FAILED
        })
    }
}

export const adminUpdateUser = (id, formValues, token) => async dispatch => {
    try{

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await api.post(`admin/users/${id}/update`, formValues, config)

        dispatch({
            type: ADMIN_UPDATE_USER,
            payload: response.data,
            id
        })

        history.push('/dashboard/users')

    } catch(error){
        dispatch({
            type: ADMIN_UPDATE_USER_FAILED
        })
    }
}

export const adminDeleteUser = (id, token) => async dispatch => {
    try{

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        await api.post(`admin/users/${id}/trash`, {}, config)

        dispatch({
            type: ADMIN_DELETE_USER,
            payload: id
        })

    } catch(error){
        dispatch({
            type: ADMIN_DELETE_USER_FAILED
        })
    }
}

export const galleryUpload = (projectId, formValues, token) => async dispatch => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    for(let i = 0; i < formValues.length; i++){
        let data = new FormData()
        data.append('file', formValues[i])
        const response = await api.post(`admin/projects/${projectId}/gallery/upload`, data, config)
        console.log(response.data)

        dispatch({
            type: ADMIN_PROJECT_GALLERY_UPLOAD,
            payload: {
                data: response.data,
                projectId
            }
        })
    } 
}

export const fetchGallery = (projectId, token) => async dispatch => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await api.get(`admin/projects/${projectId}/gallery`, config)

    dispatch({
        type: ADMIN_PROJECT_GALLERY_UPLOAD,
        payload: {
            data: response.data,
            projectId
        }
    })
}

export const deleteGalleryItem = (projectId, id, token) => async dispatch => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await api.post(`admin/projects/${projectId}/gallery/${id}/delete`, {}, config)

    dispatch({
        type: ADMIN_PROJECT_GALLERY_UPLOAD,
        payload: {
            data: response.data,
            projectId
        }
    })
}

export const updateGalleryItem = (projectId, id, value, token) => async dispatch => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const formValues = {
        order: value
    }

    const response = await api.post(`admin/projects/${projectId}/gallery/${id}/update`, formValues, config)

    dispatch({
        type: ADMIN_PROJECT_GALLERY_UPLOAD,
        payload: {
            data: response.data,
            projectId
        }
    })
}
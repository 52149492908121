import React from 'react'
// import banner from '../../../assets/img/hero-heythorpe@2x.jpg'
import AboveSectionHeading from '../Styles/AboveSectionHeading'
import Heading from '../Typography/Heading'
import SectionHeading from '../Typography/SectionHeading'
import Copy from '../Typography/Copy'
import SlickButton from '../Styles/SlickButton'
import Section from './Section'
import Column from './Column'
import img from '../../../assets/img/about-02@2x2.jpg'
import img2 from '../../../assets/img/about-01-20211109.jpg'
import ScrolledIntoView from '../../../ScrolledIntoView'

class AboutTopSection extends React.Component {
  render(){
    return(
        // <ScrolledIntoView>
            <Section className="w_about-top-section">
                <Column>
                    <ScrolledIntoView >
                        <Heading>About</Heading>
                        <Copy>
                            <p>We at Westgate & Keële are specialists in full refurbishments, loft conversions and extensions and are accredited with Trustmark and the Federation of Master Builders.</p>

                            <p>Over 20 years in the industry we have built up a network of trusted subcontractors and our managing director, Michael, personally oversees all projects from start to finish to ensure we maintain a consistently high standard and quality of workmanship on each and every project we take on.
                            </p>
                        </Copy>
                    </ScrolledIntoView>
                     <ScrolledIntoView>
                        <div className="w_img" style={{
                            backgroundImage: `url(${img})`
                        }} />
                    </ScrolledIntoView>
                </Column>
                <Column>
                    <ScrolledIntoView delay={1000}>
                        <div>
                            <img src={img2} alt="" />
                        </div>
                        <AboveSectionHeading>RICH HISTORY</AboveSectionHeading>
                        <SectionHeading>Personal approach<br />for your needs</SectionHeading>
                        <Copy>
                            <p>We are proud to be an owner-run business, with Michael Mclachlan personally meeting and consulting on every building project we take on. It is his expertise and knowledge of construction and refurbishment in the South West London area that really sets us apart from the rest.</p>
                            <p>With over 20 years’ experience in building and project management, we offer residential and commercial conversion expertise with a personal touch.</p>                
                        </Copy>
                        <SlickButton link="/contact">Get in Touch</SlickButton>
                    </ScrolledIntoView>
                </Column>
            </Section>
        // </ScrolledIntoView>
    )
  }
}

export default AboutTopSection
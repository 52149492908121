import React from 'react'
import {Link} from 'react-router-dom'

class SlickButton extends React.Component {
  render(){
    return(
        <Link to={this.props.link ? this.props.link : ""} className="w_btn w_slick-button">
          <svg>
            <rect x="0" y="0" fill="none" width="100%" height="100%"/>
          </svg>
        {this.props.children}
        </Link>
    )
  }
}

export default SlickButton
import React from 'react'

class ListStyle extends React.Component {
  render(){
    return(
        <ul className={`w_list-style ${this.props.className ? this.props.className : ''}`}>
            {this.props.children} 
        </ul>
    )
  }
}

export default ListStyle
import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import Heading from '../ui/Typography/Heading'
import Copy from '../ui/Typography/Copy'
import ScrolledIntoView from '../../ScrolledIntoView'
import SplitSection2 from '../ui/Sections/SplitSection2'
import img from '../../assets/img/services-design-20211109.jpg'
import img2 from '../../assets/img/services-build-20211109.jpg'
import img3 from '../../assets/img/services-manage-20211109.jpg'
import ListStyle from '../ui/Styles/ListStyle'
import OrderedListStyle from '../ui/Styles/OrderedListStyle'
import {Helmet} from "react-helmet"

class Services extends React.Component {
  render(){
    return(
      <React.Fragment>
        <Helmet>                      
            <title>Services | Westgate & Keële</title>
            <link rel="canonical" href={window.location} />
            <meta name="robots" content="index, follow" />
            <meta
                name="description"
                content="Westgate & Keële offer a wide range of services within the remit of a full property development. Our team of talented tradesmen will cover most aspects of the project which allows us to keep a close eye on the budget and programme – Over 20 years in the industry we have built up a network of trusted subcontractors with the expertise needed to complete all our projects to a high standard"
            />
        </Helmet>
        <Layout>
          <ScrolledIntoView>
            <Section className="w_section-sercices-1">
              <Heading>
                Services
              </Heading>
              <Copy>
                <p>Westgate & Keële offer a wide range of services within the remit of a full property development. Between our team of talented tradesmen, who will cover most aspects of the project, and our network of trusted subcontractors, we have the expertise needed to complete all our projects to a high standard, including:</p>
                <ListStyle className="w_services-list-top">
                  <li>New Builds Projects;</li>  
                  <li>Change of Use Developments;</li> 
                  <li>Lofts & Extensions;</li> 
                  <li>Full Refurbishments; and</li> 
                  <li>Commercial Fit-outs.</li>      
                </ListStyle>
                <p>We provide transparent, honest estimates of project costs and timelines and assist in managing these throughout the build, so get in touch with your specifications to discuss how we can help you achieve your project.</p>
              </Copy>
            </Section>
          </ScrolledIntoView>

          <SplitSection2
            className="w_section-services-split-section"
            img={img}
            sectionHeading="Design"
            link="/contact"
            linkText="Get in Touch"
            reverse={true}
            rtl
            id="design"
          >
            <p>Builders are practical people, right?! And we’ve been doing this for years and have seen what works well, and let’s face it, what really doesn’t! We are able to provide practical solutions for tricky layouts and enjoy working with our clients to maximise space and create functional yet aesthetically pleasing designs.</p>
            <p>Having said this, design comes in many shapes and sizes in the construction industry, and where we can’t assist you ourselves, our aim is to put you in touch with the right people for all your design requirements.</p>
            <p>We work in partnership with a team of talented and reliable design experts including:</p>
            <ListStyle>
              <li>Architects;</li>     
              <li>Draftsmen;</li> 
              <li>Planners;</li> 
              <li>Structural Engineers;</li> 
              <li>Concept Designers; and</li> 
              <li>Interior Designers.</li>        
            </ListStyle>
          </SplitSection2>

          <SplitSection2
            className="w_section-services-split-section"
            img={img2}
            sectionHeading="Build"
            link="/contact"
            linkText="Get in Touch"
            reverse={false}
            ltr
            id="build"
          >
            <p>Our primary expertise lies in building, and this is what we are most passionate about. We offer a complete turn-key service and our team covers all general aspects within the build, including:</p>
            <ListStyle>
              <li>Roofing;</li>
              <li>Timber Framework;</li>
              <li>Steel Installations;</li>
              <li>Ground Works;</li>
              <li>Mechanical Installations;</li>
              <li>Electrical Installations;</li>
              <li>Carpentry;</li>
              <li>Bespoke Joinery;</li>
              <li>Painting & Decorating; and</li>
              <li>Landscaping.</li>
            </ListStyle>
            <p>For all our building materials, we love local! And, whilst we are more than happy to collaborate with our clients chosen suppliers, we will also put forward recommendations of our trusted, local suppliers as this is a really important aspect of any build in order to ensure as few delays as possible.</p>
            <p>We are a Trustmark and Federation of Master Builders accredited builder and take great pride in our workmanship.</p>
            <p>We therefore offer retention payment options, snagging for 6 months after practical completion and a 1 year workmanship guarantee. In addition, for an extra fee, we can obtain additional insurance backed guarantees on our workmanship as well as structural warrantees.</p>
          </SplitSection2>

          <SplitSection2
            className="w_section-services-split-section w_last"
            img={img3}
            sectionHeading="Manage"
            link="/contact"
            linkText="Get in Touch"
            reverse={true}
            rtl
            id="manage"
          >
            <p>At Westgate & Keële, we understand that choosing a high-quality building contractor is only one step in the process. We believe it is the way we manage a project for our clients that sets us apart from the rest. </p>
            <p>We make ourselves available throughout a project to help clients with any questions or concerns they may have, and we proactively manage changing budgets, timelines and the myriad of moving parts that are just par for the course when it comes to building works.</p>
            <p>Our management services include:</p>
            <ListStyle>
              <li>Detailed reports at the outset of any project;</li>
              <li>Projected targets and budgeting reports;</li>
              <li>Ensuring all expectations are realistic and achievable;</li>
              <li>Detailed estimates by our trusted Quantity Surveyor giving impartial advice and budgeting supervision;</li>
              <li>Monitoring timescales;</li>
              <li>Progress reporting;</li>
              <li>Stage payments which reflect live progress;</li>
              <li>Collaboration with client’s suppliers (where relevant);</li> 
              <li>Liaising with specialist suppliers to ensure any bespoke materials are delivered to specification at the right stage of the build; and</li>
              <li>Liaising with building control, local councils, water and gas suppliers as required.</li>
            </ListStyle>
          </SplitSection2>

          <ScrolledIntoView>
            <Section className="w_section-sercices-2">
              <Copy>
                <p>We understand that choosing a building company is a very important (and sometimes stressful) decision.</p>
                <p>We are a Trustmark and Federation of Master Builders accredited builder and take great pride on our workmanship. In addition, we hope our commitment to exceptional service will put your mind at ease should you choose to entrust your next project to us. We offer:</p>
                <OrderedListStyle>
                  <li>Transparent, honest estimates of project costs.</li>
                  <li>In-house project management, available to help with any questions or concerns throughout the project.</li>
                  <li>Prompt communication when it comes to potential delays and cost variations;</li>
                  <li>Detailed estimates by our trusted Quantity Surveyor giving impartial advise and budgeting supervision;</li>
                  <li>Trusted tradesmen who are experts in their fields and will achieve the highest standard of work;</li>
                  <li>Realistic progress payments based on % of completion (We manage our cash flow such that we do not request full deposits for any aspect of our work, or for the procurement of specialised hardware, but we do ask for prompt payment upon invoicing);</li>
                  <li>Retention payment options;</li>
                  <li>Snagging for 6 months after practical completion;</li>
                  <li>1 year workmanship guarantee.</li>
                </OrderedListStyle>
              </Copy>
            </Section>
          </ScrolledIntoView>
        </Layout>
      </React.Fragment>
    )
  }
}

export default Services
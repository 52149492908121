import {
    ADMIN_USERS,
    ADMIN_CREATE_USER,
    ADMIN_USER_DETAILS,
    ADMIN_UPDATE_USER,
    ADMIN_DELETE_USER
} from '../actions/types'
import _ from 'lodash'

const initialState = {}

const adminUsersReducer = (state = initialState, action) => {    
    switch (action.type){
        case ADMIN_USERS:
            return {
                ...state,
                ..._.mapKeys(action.payload, 'id')
            }
        case ADMIN_CREATE_USER:
            return {
                ...state,
                [action.payload.id]: action.payload
            }
        case ADMIN_USER_DETAILS:
            return {
                ...state,
                [action.payload.id]: action.payload
            }
        case ADMIN_UPDATE_USER:
            return {
                ...state,
                [action.id]: action.payload
            }
        case ADMIN_DELETE_USER:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default adminUsersReducer
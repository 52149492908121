import React from 'react'

class Button extends React.Component {
  render(){
    return(
      <button className={`w_btn w_slick-button ${this.props.submitted ? '' : '' }`} onClick={this.props.onClick}>
      <svg>
        <rect x="0" y="0" fill="none" width="100%" height="100%"/>
      </svg>
        {this.props.children}
    </button>
    )
  }
}

export default Button
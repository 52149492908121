import React from 'react'

class OrderedListStyle extends React.Component {
  render(){
    return(
        <ol className="w_ordered-list-style">
            {this.props.children} 
        </ol>
    )
  }
}

export default OrderedListStyle
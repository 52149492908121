import React from 'react'
import { connect } from 'react-redux'
import {
    adminSignIn
} from '../../actions'
import {
    Field,
    reduxForm
} from 'redux-form'
import {
    Redirect
} from 'react-router-dom'
import Button from '../ui/Styles/Button'

class AdminAuthForm extends React.Component{    

    renderError({error, touched}){
		if(error && touched){
			return(
                <div className="w_error-message" role="alert">
                    {error}
                </div>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        const className = `w_form-group w_form-group-6 ${meta.error && meta.touched ? 'w_error' : ''}`

        return (
            <div className={className}>
                <label>{label}</label>
                <input type={type} className="w_form-control" {...input} autoComplete="on" placeholder="Type here" />
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.adminSignIn(formValues)
    }

    render(){

        if(this.props.auth.isSignedIn){
            return <Redirect to="/dashboard" />
        }
        
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="w_form">
                
                <Field name="email" type="email" component={this.renderInput} label="Email*" />
                <Field name="password" type="password" component={this.renderInput} label="Password*" />                
                { 
                    this.props.auth.error && (
                        <div className="w_form-group">
                            <small className="w_error">I don't recocnize the login credentials you entered. Forbidden access</small>
                        </div>
                    )
                }
                <div className="w_form-group">
                    <Button>Sign In</Button>
                </div>
                
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter your e-mail address"
    } else if(!formValues.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        errors.email = "You must enter a valid e-mail address"
    }
    

    if(!formValues.password){
        errors.password = "Please enter your password"
    }

    return errors
}

const mapStateToProps = state => {
    return {
        auth: state.adminAuthState
    }
}

const connectedComponent = connect(mapStateToProps, {
    adminSignIn
})(AdminAuthForm)

export default reduxForm({
    form: 'adminAuth',
    validate
})(connectedComponent)
import React from 'react'

class Heading extends React.Component {
  render(){
    return(
      <h1 style={{
          color: this.props.color ? this.props.color : '#212529'
      }} className="w_heading">{this.props.children}</h1>
    )
  }
}

export default Heading
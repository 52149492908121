import {
    FETCH_PROJECTS,
    FETCH_PROJECT
} from '../actions/types'
import _ from 'lodash';

const initialState = {}

const projectsReducer = (state = initialState, action) => {    
    switch (action.type){
        case FETCH_PROJECTS:
            return { ...state, ..._.mapKeys(action.payload, 'slug') };
        case FETCH_PROJECT:
            return { ...state, [action.payload.slug]: action.payload }
        default:
            return state
    }
}

export default projectsReducer
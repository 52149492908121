import { combineReducers } from 'redux'
import {reducer as formReducer} from 'redux-form'

//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import contactFormReducer from './contactFormReducer'
import adminAuthReducer from './adminAuthReducer'
import adminProjectsReducer from './adminProjectsReducer'
import projectsReducer from './projectsReducer'
import adminUsersReducer from './adminUsersReducer'
import adminGalleryReducer from './adminGalleryReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['adminAuthState']
}

const rootReducer = combineReducers({
    contactFormState: contactFormReducer,
    adminAuthState: adminAuthReducer,
    adminProjectsState: adminProjectsReducer,
    projectsState: projectsReducer,
    adminUsersState: adminUsersReducer,
    adminGalleryState: adminGalleryReducer,
    form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)
import React from 'react'

class MediumHeading extends React.Component {
  render(){
    return(
      <h3 style={{
          color: this.props.color ? this.props.color : '#212529'
      }} className="w_medium-heading">{this.props.children}</h3>
    )
  }
}

export default MediumHeading
import React from 'react'
import {Link} from 'react-router-dom'
import SlickButton from '../Styles/SlickButton'
import ScrolledIntoView from '../../../ScrolledIntoView'

class LinkColumnSection extends React.Component {

  scrollToPosition = hash => {
    setTimeout(() => {
      const element = document.querySelector(hash)
      const topPos = element.getBoundingClientRect().top + window.pageYOffset - 150
      
      window.scrollTo({
          top: topPos, // scroll so that the element is at the top of the view
          behavior: 'smooth' // smooth scroll
      })
    },500)    
  }

  handleBackgroundSwitch = i => {
    let bgImages = document.querySelectorAll('.w_bg-image')
    for (let a = 0; a < bgImages.length; a++){
      bgImages[a].classList.remove('w_active')
    }
    bgImages[i].classList.add('w_active')
  }

  renderLinks = () => {
    return this.props.links.map((link, index) => {
      return (
        <Link 
          to={`/services${link.link}`} 
          className="w_link-column" 
          key={index}
          onMouseOver={() => this.handleBackgroundSwitch(index)}
          style={{
            backgroundImage: `url(${link.img})`
          }}
        >
            <div onClick={() => this.scrollToPosition(link.link)}>
              <h2 className="w_link-heading">{link.heading}</h2>            
              <SlickButton link={`/services${link.link}`}>Learn More</SlickButton>            
              <div className="w_overlay" />
            </div>
        </Link>
      )
    })
  }

  renderBgImages = () => {
    return this.props.links.map((link,index) => {
      return <div key={index} className={`w_bg-image ${index === 1 ? 'w_active' : ''}`} 
        style={{
          backgroundImage: `url(${link.img})`
        }}
      />
    })
  }

  render(){
    return(
      <ScrolledIntoView fade>
        <section 
          className={`w_link-column-section ${this.props.className ? this.props.className : ''}`}
        >
          <div className="w_link-inner">
            {this.renderLinks()}
          </div>
          {this.renderBgImages()}
        </section>
      </ScrolledIntoView>
    )
  }
}

export default LinkColumnSection
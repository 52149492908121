import React from 'react'
import Layout from '../ui/Layout/Layout'
import Section from '../ui/Sections/Section'
import ScrolledIntoView from '../../ScrolledIntoView'
import Column from '../ui/Sections/Column'
import Heading from '../ui/Typography/Heading'
import {connect} from 'react-redux'
import {
  fetchProject
} from '../../actions'
import {BASE_PATH} from '../../paths'
import Copy from '../ui/Typography/Copy'
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet"

class Project extends React.Component {

  componentDidMount(){
    if(this.props.match){
      this.props.fetchProject(this.props.match.params.slug)
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.match){
      if(this.props.match.params.slug !== prevProps.match.params.slug){
        this.props.fetchProject(this.props.match.params.slug)
      }
    }
  }

  EmbedVideo = () => {
    return (
        <div  className="w_item w_item-video" dangerouslySetInnerHTML={{ __html: `
            <video title="ldbYoutubeVideo" width="560" height="315" autoplay loop playsinline controls>
                <source src=${BASE_PATH + this.props.video} type="video/mp4" />

            Your browser does not support the video tag.
            </video>
            ` }}></div>
        )
    }

  renderGallery = () => {
    return this.props.project.gallery.map((item) => {
      return (
        <div className="w_item w_item-img">
          <img src={BASE_PATH + item.path} alt="" />
        </div>
      )
    })
  }

  render(){

    if(!this.props.project) {
      return <></>
    }

    const { title, body, video, featured_image, previous, next } = this.props.project

    return(
      <React.Fragment>
        <Helmet>                      
            <title>{title} | Westgate & Keële</title>
            <link rel="canonical" href={window.location} />
            <meta name="robots" content="index, follow" />
            <meta
                name="description"
                content={body}
            />
        </Helmet>
        <Layout>
          <ScrolledIntoView>            
          <Section className="w_section-project-top">
              <Column>
                <img src={BASE_PATH + featured_image} alt={title} />
              </Column>
              <Column>
                <div className="w_column-inner">
                  <Heading>{title}</Heading>
                  <Copy>
                    <div dangerouslySetInnerHTML={{__html:body}} />
                  </Copy>
                </div>
              </Column>
          </Section>
          {/* <Section className="w_section-project-gallery">
            
            
          </Section> */}
          {
              video && (
                <div  className="w_item w_item-video" dangerouslySetInnerHTML={{ __html: `
                <video title="YoutubeVideo" width="560" height="315" autoplay loop playsinline controls>
                    <source src=${BASE_PATH + video} type="video/mp4" />

                Your browser does not support the video tag.
                </video>
                ` }}></div>
              )
            }
          {this.renderGallery()}
          <Section className="w_section-project">
            <Column className="w_column-project-details">                    
              <ul className="w_project-previous-next-nav">
                <li>
                  <Link to={`/projects/${previous}`} >
                    <span>Previous Project</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/projects/${next}`} >
                    <span>Next Project</span>
                  </Link>
                </li>
              </ul>
            </Column>
        </Section>
          </ScrolledIntoView>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    project: ownProps.match ? state.projectsState[ownProps.match.params.slug] : null
  }
}

export default connect(mapStateToProps, {
  fetchProject
})(Project)
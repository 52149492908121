import React from 'react'
import SmallSectionHeading from '../Typography/SmallSectionHeading'

class AboveSectionHeading extends React.Component {
  render(){
    return(
      <div className="w_above-heading">
        <SmallSectionHeading>
            {this.props.children}
        </SmallSectionHeading>            
      </div>
    )
  }
}

export default AboveSectionHeading
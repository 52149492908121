import React from 'react'

class SmallHeading extends React.Component {
  render(){
    return(
      <h4 style={{
          color: this.props.color ? this.props.color : '#212529'
      }} className="w_small-heading">{this.props.children}</h4>
    )
  }
}

export default SmallHeading
import {
    ADMIN_SIGNIN,
    ADMIN_SIGNIN_FAILED,
    ADMIN_SIGNOUT
} from '../actions/types'

const initialState = {
    isSignedIn: false,
    token: null,
    error: null
}

const adminAuthReducer = (state = initialState, action) => {    
    switch (action.type){
        case ADMIN_SIGNIN:
            return {
                ...state,
                isSignedIn: true,
                token: action.payload.token,
                error: null
            }
        case ADMIN_SIGNIN_FAILED:
            return {
                ...state,
                isSignedIn: false,
                token: null,
                error: action.payload.message
            }
        case ADMIN_SIGNOUT:
            return initialState
        default:
            return state
    }
}

export default adminAuthReducer